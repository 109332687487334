import React, {Component} from 'react';
import PropTypes from "prop-types"
import {Col, Grid, Panel, Row, Table,} from "react-bootstrap"
import Dollars from "../../components/Dollars"
import moment from "moment";
import StaticMap from "../PrintProposalPage/StaticMap";
import squarePng from "../../images/square.png";
import PlantName from "../../components/PlantName";
import './NewWorkOrderPrintOutput.css'

export class NewWorkOrderPrintOutput extends Component {

    rendered = 0;

    renderServices = work_order => {
        return this.props.options && (
            <div>
                <br/>
                <Row>
                    <Col xs={1}>
                        <h6>Svc #</h6>
                    </Col>
                    <Col xs={4}>
                        <h6>Service Description</h6>
                    </Col>
                    <Col xs={3}>
                        <h6>Asset(s)</h6>
                    </Col>
                    <Col xs={1}>
                        {this.props.options.estimatedHours ? <h6>Est. Hrs.</h6> : null}
                    </Col>
                    <Col xs={1}>
                        <h6>Actual Hrs.</h6>
                    </Col>
                    <Col xs={1}>
                        {this.props.options.priceEachService ? <h6>Price</h6> : null}
                    </Col>
                    <Col xs={1}>
                        <h6>Completed</h6>
                    </Col>
                </Row>

                {work_order.work_order_proposal_services &&
                    work_order.work_order_proposal_services
                        .slice()
                        .filter((r) => r.removal_service !== true)
                        .sort((a, b) => a.service_no - b.service_no)
                        .map(ps => {
                            return ps.assets.map((a, idx) => {
                                return (
                                    <div>
                                        <Row className="avoid_page_breaking_inside">
                                            <Col xs={1}>
                                                {idx === 0 ? ps.service_no : null}
                                            </Col>
                                            <Col xs={4} className="preserve-text-area-format">
                                                <div style={{display: idx === 0 ? 'block' : 'none'}}>
                                                    <strong>{ps.service_name}</strong>{' '}
                                                    <h6 className="text-danger">
                                                        {
                                                            this.props.options.promisedDates && ps.promised_date_start ? `Promised:
                              ${moment(ps.promised_date_start).format('l')} - ${moment(ps.promised_date_end).format('l')}`
                                                                : null
                                                        }
                                                    </h6>
                                                    <strong>
                                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                        {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                            ps.priorities.map(p => <span
                                                                key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                    </strong>
                                                    <br/>
                                                    {this.props.options.serviceNotes &&
                                                        ps.proposal_service_note}
                                                    <br/>
                                                    {this.props.options.workOrderNotes &&
                                                    ps.ps_work_order_note && ps.ps_work_order_note !== null
                                                        ? `WO Note: ${ps.ps_work_order_note}`
                                                        : null}
                                                </div>
                                            </Col>

                                            <Col xs={3}>
                                                <p>
                                                    <strong>
                                                        #{a.asset_label}{" "}<PlantName plant={a.name}/>
                                                    </strong>
                                                    {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                    {this.props.options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                    <br/>
                                                    {this.props.options.locationDescription && (this.props.options.locationDescription !== 'null' || this.props.options.locationDescription.length > 0)
                                                        ? `Location: ${a.location}`
                                                        : null}
                                                    {this.props.options.locationDescription ? <br/> : null}
                                                    {this.props.options.assetNotes && a.note && a.note.length > 1
                                                        ? `Note: ${a.note}`
                                                        : null}
                                                    {this.props.options.assetNotes ? <br/> : null}
                                                    <h5 className="text-warning">
                                                        <strong>
                                                            {this.props.options.siteHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                    return (
                                                                        `${ah.asset_hazard}`
                                                                    )
                                                                })}`
                                                                : null}
                                                        </strong>
                                                    </h5>
                                                </p>
                                            </Col>

                                            <Col xs={1}>
                                                {idx === 0
                                                    ? this.props.options.estimatedHours ? ps.est_hours : null
                                                    : null}
                                            </Col>

                                            <Col xs={1}>
                                                <img
                                                    alt="checkbox"
                                                    src={squarePng}
                                                    style={{height: 20, width: 40}}
                                                />
                                            </Col>

                                            <Col xs={1} className="assetRow">
                                                {idx === 0
                                                    ? this.props.options.priceEachService
                                                        ? <Dollars amount={ps.price}/>
                                                        : null
                                                    : null}
                                            </Col>

                                            <Col xs={1}>
                                                <img
                                                    alt="checkbox"
                                                    src={squarePng}
                                                    style={{height: 20, width: 20}}
                                                />
                                            </Col>

                                        </Row>
                                        <p>&nbsp;</p>
                                    </div>
                                )
                            })
                        })}

            </div>
        )
    };

    renderRemovalServices = work_order => {
        if (work_order.work_order_proposal_services && work_order.work_order_proposal_services.length > 0 && work_order.work_order_proposal_services
            .filter((r) => r.removal_service === true).length < 1) {
            return null
        }

        return this.props.options && (
            <div className="removalServices text-large avoid_page_breaking_inside">
                <Panel>
                    <div className="removal-services-header text-center">REMOVAL SERVICES</div>
                    <br/>

                    {work_order.work_order_proposal_services &&
                        work_order.work_order_proposal_services
                            .slice()
                            .filter((r) => r.removal_service === true)
                            .sort((a, b) => a.service_no - b.service_no)
                            .map(ps => {
                                return ps.assets.map((a, idx) => {
                                    return (
                                        <div>
                                            <Row className="avoid_page_breaking_inside">
                                                <Col xs={1}>
                                                    <div className="removal-text">
                                                        {idx === 0 ? ps.service_no : null}
                                                    </div>
                                                </Col>
                                                <Col xs={4} className="preserve-text-area-format">
                                                    <div style={{display: idx === 0 ? 'block' : 'none'}}>
                                                        <div className="removal-text">{ps.service_name}</div>
                                                        {' '}
                                                        <h6 className="text-danger">
                                                            {
                                                                this.props.options.promisedDates && ps.promised_date_start ? `Promised:
                              ${moment(ps.promised_date_start).format('l')} - ${moment(ps.promised_date_end).format('l')}`
                                                                    : null
                                                            }
                                                        </h6>
                                                        <strong>
                                                            {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 ? 'Priority: ' : null}
                                                            {this.props.options.servicePriorities && ps.priorities && ps.priorities.length > 0 &&
                                                                ps.priorities.map(p => <span
                                                                    key={p.id}>{p.name}{ps.priorities.length > 1 ? ', ' : ' '}</span>)}
                                                        </strong>
                                                        <br/>
                                                        {this.props.options.serviceNotes &&
                                                            ps.proposal_service_note}
                                                        <br/>
                                                        {this.props.options.workOrderNotes &&
                                                        ps.ps_work_order_note.length > 1
                                                            ? `WO Note: ${ps.ps_work_order_note}`
                                                            : null}
                                                    </div>
                                                </Col>

                                                <Col xs={3}>
                                                    <p>
                                                        <div className="removal-text">
                                                            #{a.asset_label} <PlantName plant={a.name}/>
                                                        </div>
                                                        {a.plant_count > 1 ? ` [${a.plant_count}]` : null}
                                                        {this.props.options.dbh && a.dbh ? ` DBH: ${a.dbh}"` : null}
                                                        <br/>
                                                        {this.props.options.locationDescription && (this.props.options.locationDescription !== 'null' || this.props.options.locationDescription.length > 0)
                                                            ? `Location: ${a.location}`
                                                            : null}
                                                        {this.props.options.locationDescription ? <br/> : null}
                                                        {this.props.options.assetNotes && a.note && a.note.length > 1
                                                            ? `Note: ${a.note}`
                                                            : null}
                                                        {this.props.options.assetNotes ? <br/> : null}
                                                        <h5 className="text-warning">
                                                            <strong>
                                                                {this.props.options.siteHazards && a.asset_hazards && a.asset_hazards.length >= 1
                                                                    ? `HAZARD: ${a.asset_hazards.map(ah => {
                                                                        return (
                                                                            `${ah.asset_hazard}`
                                                                        )
                                                                    })}`
                                                                    : null}
                                                            </strong>
                                                        </h5>
                                                    </p>
                                                </Col>

                                                <Col xs={1}>
                                                    {idx === 0
                                                        ? this.props.options.estimatedHours ? ps.est_hours : null
                                                        : null}
                                                </Col>

                                                <Col xs={1}>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: 20, width: 40}}
                                                    />
                                                </Col>

                                                <Col xs={1} className="assetRow">
                                                    {idx === 0
                                                        ? this.props.options.priceEachService
                                                            ? <Dollars amount={ps.price}/>
                                                            : null
                                                        : null}
                                                </Col>

                                                <Col xs={1}>
                                                    <img
                                                        alt="checkbox"
                                                        src={squarePng}
                                                        style={{height: 20, width: 20}}
                                                    />
                                                </Col>

                                            </Row>
                                            <p>&nbsp;</p>
                                        </div>
                                    )
                                })
                            })}

                </Panel>
            </div>
        )
    };

    renderSiblingServicesFromProposal = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        const proposal_services_in_this_wo = work_order.work_order_proposal_services.map(
            wops => wops.proposal_service_id
        )

        return (
            <Panel
                header="Other services from this proposal"
                className="avoid_page_breaking_inside"
            >
                {work_order.sibling_services &&
                    work_order.sibling_services
                        .filter(
                            ss =>
                                !proposal_services_in_this_wo.includes(ss.proposal_service_id)
                        )
                        .map(ss => (
                            <p key={ss.proposal_service_id}>
                                {ss.service_name} {ss.service_name}: {ss.status}
                            </p>
                        ))}
            </Panel>
        )
    };

    viewLoaded = () => {
        setTimeout(() => {
            window.status = 'rendered';
        }, 1000);
    };

    renderSiteMap = (work_order, mapNum) => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }
        return (
            <div className="text-center top25">
                <StaticMap
                    size="600x600"
                    zoom={this.props.options && !this.props.options.fitBounds && (mapNum === 1 ? work_order.site_zoom : 20)}
                    center={this.props.options && !this.props.options.fitBounds && (`${work_order.site_lat},${work_order.site_lng}`)}
                    maptype={work_order.site_map_type || "hybrid"}
                    workOrderToken={work_order.wo_token}
                    onRender={() => this.viewLoaded()}
                    fitBounds={this.props.options.fitBounds}
                />
            </div>
        );
    };

    renderPageHeader = work_order => {
        return this.props.options && (<div>
            <Row>
                <Row>
                    <Col xs={12}>
                        <h5 className="text-warning">
                            <strong>
                                {this.props.options.jsa && work_order.hazard_description && work_order.hazard_description.length > 0
                                    ? `JSA: ${work_order.hazard_description}`
                                    : null}
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h5 className="text-warning">
                            <strong>
                                {this.props.options.siteHazards && work_order.site_hazards && work_order.site_hazards.length >= 1
                                    ? `SITE HAZARDS: ${work_order.site_hazards.map(sh => {
                                        return (
                                            ` ${sh.site_hazard}`
                                        )
                                    })}`
                                    : null}
                            </strong>
                        </h5>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <h5 className="pad-left">
                            Work Order: {work_order.work_order_no}
                        </h5>
                    </Col>

                    <Col xs={4}>
                        <h5 className="pad-left">
                            Proposal #{work_order.proposal_no}
                        </h5>
                    </Col>

                    <Col xs={4} className="text-right">
                        <h5>
                            Sales Arborist: {work_order.sales_arborist_name}
                        </h5>
                    </Col>
                </Row>
            </Row>

            <Row>
                <Col xs={4}>
                    <div className="pad-left">
                        <strong>{work_order.customer_name}</strong><br/>
                        {work_order.customer_address_1}
                        <br/>
                        {work_order.customer_address_2 ? work_order.customer_address_2 : null}
                        {work_order.customer_address_2 ? <br/> : null}
                        {work_order.customer_city}, {work_order.customer_state}{' '}
                        {work_order.customer_zip}
                    </div>
                </Col>

                <Col xs={4}>
                    <div className="pad-left">
                        Site
                        Contact: <strong>{work_order.site_contact_first_name} {work_order.site_contact_last_name}</strong>
                        <br/>
                        Phone: {work_order.customer_phone}
                        <br/>
                        Email: {work_order.customer_email_address}
                    </div>
                </Col>

                <Col xs={4}>
                    <strong>Site Details</strong>
                    <br/>
                    Name: {work_order.site_name}
                    <br/>
                    {work_order.site_full_address}
                </Col>
            </Row>
        </div>)
    };

    renderEquipment = work_order => {
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    {(work_order.equipment === undefined || work_order.equipment.length === 0) ?
                        null :
                        <strong>Equipment: {work_order.equipment.join(', ')}</strong>
                    }
                </Col>
            </Row>
        )
    };

    renderTotalStaffHours = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        const total = work_order.work_order_proposal_services.reduce(
            (total, wops) => {
                return total + (wops.est_hours === null ? 0 : wops.est_hours)
            },
            0
        )

        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12} className="text-right">
                    <strong>
                        Total Staff Hours: {total}
                    </strong>
                </Col>
            </Row>
        )
    };

    renderTotalPrice = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        const total = work_order.work_order_proposal_services.reduce(
            (total, wops) => {
                return total + wops.price
            },
            0
        );

        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12} className="text-right">
                    <strong>
                        Total Price: {<Dollars amount={total}/>}
                    </strong>
                </Col>
            </Row>
        )
    };

    renderClientHeader = work_order => {
        return this.props.options && <div><Row>
            <Col xs={12} className="text-center">
                <img
                    alt="company header"
                    className="logoHeader"
                    src={work_order.work_order_no}
                />
            </Col>
        </Row>
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    {work_order && work_order.events ?
                        <h5 className="text-danger">Scheduled for: {work_order.events.map(e => (
                            <div className="floating-box">
                                {`${e.employee === null ? "Any crew" : e.employee} on ${e.start_date}`}
                            </div>
                        ))
                        }</h5>
                        : null
                    }
                </Col>
            </Row>
        </div>
    };

    renderApplicationSites = work_order => {
        if (
            work_order.application_sites === undefined ||
            work_order.application_sites.length === 0
        ) {
            return null
        }

        return (
            <Row>
                <Col xs={12}>
                    <strong>Application Sites:</strong>{' '}
                    {work_order.application_sites
                        .map(a => `${a.name}: ${a.description}`)
                        .join(', ')}
                </Col>
            </Row>
        )
    };

    renderConsumables = work_order => {
        if (
            work_order.consumables === undefined ||
            work_order.consumables.length === 0
        ) {
            return null
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <strong>Consumables</strong>: {work_order.consumables.join(', ')}
                </Col>
            </Row>
        )
    };

    renderWeatherConditions = () => {
        return (
            <Panel header="Weather Conditions" className="avoid_page_breaking_inside weather-conditions-panel">
                <Table>
                    <thead>
                    <tr>
                        <th>Consumable</th>
                        <th>Quantity Used</th>
                        <th>Wind</th>
                        <th>Temperature</th>
                        <th>Record of App</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan="5">
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Panel>
        )
    };

    renderApplicatorNotes = () => {
        return (
            <Panel header="Applicator Notes" className="avoid_page_breaking_inside">
                <table className="applicatorNotes">
                    <thead>
                    <tr>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <Row>
                    <Col xs={3} sm={2}>
                        Applicators:
                    </Col>
                    <Col xs={2}>
                        Start
                    </Col>

                    <Col xs={2}>
                        Stop
                    </Col>

                    <Col xs={3} sm={4}>
                        Total Hours:
                        {' '}
                        <img
                            alt="hours box"
                            src={squarePng}
                            style={{height: 20, width: 60}}
                        />
                    </Col>

                    <Col xs={2}>
                        Equipment:
                    </Col>

                </Row>
                <p>&nbsp;</p>
                <Row>
                    <Col xs={12}>
                        <img
                            alt="checkbox"
                            src={squarePng}
                            style={{height: 20, width: 20}}
                        />
                        {' '}
                        STAY AWAY FROM THE TREATED TREES UNTIL DRY
                    </Col>
                </Row>
            </Panel>
        )
    };

    maybeRenderPhc(work_order) {
        if (this.props.options && this.props.options.format !== 'Plant Health Care') {
            return null
        }

        return (
            <div className="avoid_page_breaking_inside">
                {this.renderApplicationSites(work_order)}

                {this.renderConsumables(work_order)}

                <p>&nbsp;</p>

                {this.renderWeatherConditions()}

                {this.renderApplicatorNotes()}
            </div>
        )
    }

    renderPhotos = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        let photos = []

        work_order.work_order_proposal_services.forEach(wops => {
            wops.assets.forEach(a => {
                a.photos.forEach(p => {
                    photos.push(
                        <Table bordered key={p}>
                            <thead/>
                            <tbody>
                            <tr>
                                <td className="text-center">
                                    <img alt="tree" src={p}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    #{a.asset_label} <PlantName plant={a.name}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    )
                })
            })
        });

        if (photos.length === 0) {
            photos = <p>No Asset Photos Exist</p>
        }

        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {photos}
                </Col>
            </Row>
        )
    };
    renderHazardPhotos = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        let asset_hazard_pics = [];

        work_order.work_order_proposal_services.forEach(wops => {
            wops.assets.forEach(a => {
                a.asset_hazards.forEach(ah => {
                    ah.asset_hazard_pics.forEach(hp => {
                        asset_hazard_pics.push(
                            <Table bordered key={hp}>
                                <thead/>
                                <tbody>
                                <tr>
                                    <td className="text-center">
                                        <img alt="tree" src={hp}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center">
                                        <h6 className="text-danger">Tree Hazard for:
                                            #{a.asset_label} <PlantName plant={a.name}/>
                                        </h6>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        )
                    })
                })
            })
        });

        if (asset_hazard_pics.length === 0) {
            asset_hazard_pics = <p>No Hazard Photos Exist</p>
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {asset_hazard_pics}
                </Col>
            </Row>
        )
    };
    renderSiteHazardPhotos = work_order => {
        if (work_order.work_order_proposal_services === undefined) {
            return null
        }

        let site_hazard_pics = [];

        work_order.site_hazards.forEach(sh => {
            sh.site_hazard_pics.forEach(p => {
                site_hazard_pics.push(
                    <Table bordered key={p}>
                        <thead/>
                        <tbody>
                        <tr>
                            <td className="text-center">
                                <img alt="tree" src={p}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-center">
                                <h6 className="text-danger">Site Hazard:
                                    {" "}{work_order.site_full_address}
                                </h6>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                )
            })
        });

        if (site_hazard_pics.length === 0) {
            site_hazard_pics = <p>No Hazard Photos Exist</p>
        }
        return (
            <Row className="avoid_page_breaking_inside">
                <Col xs={12}>
                    <h5>Photos</h5>
                    {site_hazard_pics}
                </Col>
            </Row>
        )
    };

    render() {
        const {work_order} = this.props;
        return (
            <Grid id="print_work_order">
                <Row>
                    <Col xs={12}>
                        {this.renderClientHeader(work_order)}

                        {this.renderPageHeader(work_order)}

                        {this.renderEquipment(work_order)}

                        {this.props.options && this.props.options.estimatedHours && this.renderTotalStaffHours(work_order)}

                        {this.props.options && this.props.options.proposalTotal && this.renderTotalPrice(work_order)}

                        {this.renderServices(work_order)}

                        {this.renderRemovalServices(work_order)}

                        {this.renderSiblingServicesFromProposal(work_order)}

                        {this.maybeRenderPhc(work_order)}

                        {this.props.options && this.props.options.proposalTotal && this.renderTotalPrice(work_order)}

                        {this.props.options && this.props.options.largeAssetPhotos && this.renderPhotos(work_order)}
                        {this.props.options && this.props.options.largeAssetPhotos && this.renderHazardPhotos(work_order)}
                        {this.props.options && this.props.options.largeAssetPhotos && this.renderSiteHazardPhotos(work_order)}

                        {this.props.options && this.props.options.maps && this.renderSiteMap(work_order, 1)}

                        <div className="hidden-print">
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <hr style={{borderTop: '2px dashed', color: 'white'}}/>
                        </div>
                    </Col>
                </Row>
            </Grid>
        );
    }
}

NewWorkOrderPrintOutput.propTypes = {
    work_order: PropTypes.object.isRequired,
    options: PropTypes.object
};
